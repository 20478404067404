import country from '@/config/country';

export interface DropdownOption {
  text: string,
  value: string | number
}

const malaysiaStates: Array<DropdownOption> = [
  {
    'text': 'Please select state',
    'value': ''
  },
  {
    'text': 'Johor',
    'value': 'Johor'
  },
  {
    'text': 'Kedah',
    'value': 'Kedah'
  },
  {
    'text': 'Kelantan',
    'value': 'Kelantan'
  },
  {
    'text': 'Melaka',
    'value': 'Melaka'
  },
  {
    'text': 'Negeri Sembilan',
    'value': 'Negeri Sembilan'
  },
  {
    'text': 'Pahang',
    'value': 'Pahang'
  },
  {
    'text': 'Perak',
    'value': 'Perak'
  },
  {
    'text': 'Perlis',
    'value': 'Perlis'
  },
  {
    'text': 'Pulau Pinang',
    'value': 'Pulau Pinang'
  },
  {
    'text': 'Sabah',
    'value': 'Sabah'
  },
  {
    'text': 'Sarawak',
    'value': 'Sarawak'
  },
  {
    'text': 'Selangor',
    'value': 'Selangor'
  },
  {
    'text': 'Terengganu',
    'value': 'Terengganu'
  },
  {
    'text': 'W.Persekutuan (KL)',
    'value': 'W.Persekutuan (KL)'
  },
  {
    'text': 'W.Persekutuan (LABUAN)',
    'value': 'W.Persekutuan (LABUAN)'
  },
  {
    'text': 'W.Persekutuan (PUTRAJAYA)',
    'value': 'W.Persekutuan (PUTRAJAYA)'
  }
];

const autoTintedLocations:Array<DropdownOption> = [
  {
    'text': 'Tinted Location',
    'value': ''
  },
  {
    'text': 'Front Windscreen',
    'value': 'Front Windscreen'
  },
  {
    'text': 'Rear Windscreen',
    'value': 'Rear Windscreen'
  },
  {
    'text': 'Sunroof',
    'value': 'Sunroof'
  },
  {
    'text': '1st Row Left',
    'value': '1st Row Left'
  },
  {
    'text': '1st Row Right',
    'value': '1st Row Right'
  },
  {
    'text': '2nd Row Left',
    'value': '2nd Row Left'
  },
  {
    'text': '2nd Row Right',
    'value': '2nd Row Right'
  },
  {
    'text': '3rd Row Left',
    'value': '3rd Row Left'
  },
  {
    'text': '3rd Row Right',
    'value': '3rd Row Right'
  }
];

function getCountries () : DropdownOption[] {
  return country.map((c) => {
    return {
      'text': c.country,
      'value': c.country
    };
  });
}

function getCountryStates (countryName: string):DropdownOption[] {
  const selectedCountry = country.find(c => c.country === countryName);

  if (selectedCountry) {
    if (selectedCountry.states.length > 0) {
      return selectedCountry.states.map(s => {
        return {
          'text': s,
          'value': s
        };
      });
    } else {
      return [
        {
          'text': selectedCountry.country,
          'value': selectedCountry.country
        }
      ];
    }
  }
  return [];
}

const countries = getCountries();
countries.unshift(
  {
    'text': 'Please select country',
    'value': ''
  });

export {
  malaysiaStates,
  autoTintedLocations,
  countries,
  getCountryStates
};
