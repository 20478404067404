




























































































































































































































































































import { VeeValidateObserverRef } from '@/types/libraries/VeeValidateObserverRef';
import { ApiValidationErrorResponse } from '@/types/api/Response';
import Vue from 'vue';
import mixinsVue from './mixins.vue';
import { DropdownOption, malaysiaStates, countries, getCountryStates } from '@/dropdown-options';
import { DealerTypeDataResponse } from '@/types/api/DropdownData';
export default Vue.extend({
  'name': 'ModalAddNewDealer',
  'mixins': [mixinsVue],
  'props': {
    'value': {
      'type': Object,
      'required': true
    }
  },
  data () {
    return {
      countries,
      'isAdd': true,
      'malaysiaStates': [] as Array<DropdownOption>,
      'dealerTypes': [] as Array<DropdownOption>
    };
  },
  mounted () {
    this.malaysiaStates = malaysiaStates;

    this.getDealerTypes();
  },
  'computed': {
    stateDropdowns ():DropdownOption[] {
      const selectedCountry = this.value.address.country;

      const states = getCountryStates(selectedCountry);

      states.unshift({
        'text': states.length > 0 ? 'Please select state' : 'Please select country first',
        'value': ''
      });

      return states;
    }
  },
  'methods': {
    getDealerTypes () {
      this.axios.get('data/dealer-types').then(({ data }) => {
        const response = data as DealerTypeDataResponse;

        const list = response.result.dealerTypes;
        this.dealerTypes = list.map((dealerType) => ({
          'text': dealerType.name,
          'value': dealerType.id
        }));

        if (!this.dealerTypes.find(d => d.value === '')) {
          this.dealerTypes.unshift({
            'text': 'Please select dealer type',
            'value': ''
          });
        }
      });
    },
    onSubmit () {
      this.$emit('submit');
    },
    update () {
      this.isAdd = false;
      (this.$refs.form as any).reset();
      (this as any).show();
    },
    create () {
      this.isAdd = true;
      (this.$refs.form as any).reset();
      (this as any).show();
    },
    setErrors (errors: ApiValidationErrorResponse) {
      (this.$refs.form as VeeValidateObserverRef).setErrors(errors);
    }
  }
});
