import { Pagination } from '../Pagination';
import { FilterRequest } from './FilterRequest';
import { ApiResponse } from './Response';

// #region  list
export type DealerListRequest = FilterRequest

export interface DealerListDetailResponse {
  'id': number,
  'dealerDetail.dealerType.name': string,
  'customId': string,
  'name': string,
  'email': string,
  'phone': string,
  'dealerType.createdAt': string,
  'dealerType.createdBy.name': string,
  'isActive': string
}

interface DealerListResult extends Pagination {
  items: Array<DealerListDetailResponse>
}
export interface DealerListResponse extends ApiResponse {
  result: DealerListResult
}
// #endregion

// #region single
interface DealerAddress {
  address1: string,
  address2: string,
  postcode: string,
  city: string,
  state: string,
  country: string
}
export interface DealerDetailResponse {
  'id': number,
  'customId': string,
  'email': string,
  'name': string,
  'phone': string,
  'isActive': true,
  'dealerTypeId': number,
  'address': DealerAddress
}

export interface DealerResponse extends ApiResponse {
  result: DealerDetailResponse
}

export function createNewDealerDetailResponse (): DealerDetailResponse {
  return {
    'id': 0,
    'customId': '',
    'email': '',
    'name': '',
    'phone': '',
    'isActive': true,
    'dealerTypeId': 0,
    'address': {
      'address1': '',
      'address2': '',
      'city': '',
      'postcode': '',
      'state': '',
      'country': ''
    }
  };
}

export function assignDealerDetails (to: DealerDetailResponse, from: DealerDetailResponse): DealerDetailResponse {
  to.id = from.id;
  to.customId = from.customId;
  to.email = from.email;
  to.name = from.name;
  to.phone = from.phone;
  to.isActive = from.isActive;
  to.dealerTypeId = from.dealerTypeId;
  to.address.address1 = from.address.address1;
  to.address.address2 = from.address.address2;
  to.address.city = from.address.city;
  to.address.postcode = from.address.postcode;
  to.address.state = from.address.state;
  to.address.country = from.address.country;

  return to;
}
// #endregion

// #region form
export interface DealerForm {
  dealerId?: number,
  dealerTypeId: number | string,
  customId: string,
  name: string,
  email: string,
  phone: string,
  isActive: boolean
  address: DealerAddress,
  isSubmitting: boolean
}

export function initForm (): DealerForm {
  return {
    'dealerId': 0,
    'dealerTypeId': '',
    'customId': '',
    'email': '',
    'isActive': true,
    'name': '',
    'phone': '',
    'address': {
      'address1': '',
      'address2': '',
      'city': '',
      'postcode': '',
      'state': '',
      'country': ''
    },
    'isSubmitting': false
  };
}

export function resetForm (form: DealerForm): void {
  form.dealerId = 0;
  form.dealerTypeId = '';
  form.customId = '';
  form.email = '';
  form.isActive = true;
  form.name = '';
  form.phone = '';

  form.address.address1 = '';
  form.address.address2 = '';
  form.address.city = '';
  form.address.postcode = '';
  form.address.state = '';
  form.address.country = '';
}

export function updateForm (form: DealerForm, formDetails: DealerDetailResponse): void {
  form.dealerId = formDetails.id;
  form.dealerTypeId = formDetails.dealerTypeId;
  form.customId = formDetails.customId;
  form.email = formDetails.email;
  form.isActive = formDetails.isActive;
  form.name = formDetails.name;
  form.phone = formDetails.phone;

  form.address.address1 = formDetails.address.address1;
  form.address.address2 = formDetails.address.address2;
  form.address.city = formDetails.address.city;
  form.address.postcode = formDetails.address.postcode;
  form.address.state = formDetails.address.state;
  form.address.country = formDetails.address.country;
}
// #endregion
